export type CsvColumnConfig<TDto> = {
	header?: string;
	render?: (
		dto: TDto,
		cellPosition: { columnNum: number; rowNum: number }
	) => string | number | null | undefined;
};

export type CsvColumnWithSubTableConfig<TDto, STDto> = CsvColumnConfig<TDto> & {
	subTable: SubCsvTableConfig<TDto, STDto>;
};

export type SubCsvTableConfig<TDto, STDto> = {
	property: keyof TDto;
	columns: CsvColumnConfig<STDto>[];
	dataProvider: (dto: TDto) => Promise<STDto[]>;
};

type CsvFormatOptions = {
	includeHeaders?: boolean;
	delimiter?: string;
	newline?: string;
};

export type CsvExporterOptions<TDto> = CsvFormatOptions & {
	fileName?: string;
	contentPre?: string;
	contentPos?: string;
	expandedSubTable?: keyof TDto;
};

export type CsvExporter<TDto> = (data: TDto[], options?: CsvExporterOptions<TDto>) => void;

export function useCsvExporter<TDto>(columns: CsvColumnConfig<TDto>[]): CsvExporter<TDto> {
	return async (data: TDto[], options: CsvExporterOptions<TDto> = {}) => {
		const {
			fileName = "export.csv",
			contentPre = "",
			contentPos = "",
			expandedSubTable,
			...rest
		} = options;
		const csvContent = await parseToCsv(data, columns, expandedSubTable, rest);
		const blob = buildFileBlob(csvContent, contentPre, contentPos);
		downloadFile(blob, fileName);
	};
}

async function parseToCsv<TDto>(
	data: TDto[],
	columns: CsvColumnConfig<TDto>[],
	expandedSubTable: keyof TDto | undefined,
	options: CsvFormatOptions
): Promise<string> {
	const { includeHeaders = true, delimiter = ",", newline = "\n" } = options;

	// headers
	const headerRow = includeHeaders
		? [columns.map((column) => escapeCsvContent(column.header ?? "")).join(delimiter)]
		: [];

	// rows
	const dataRows = data.map(async (item, rowNum) => {
		const row = columns
			.map((column, columnNum) =>
				escapeCsvContent(column.render?.(item, { rowNum, columnNum }) ?? "")
			)
			.join(delimiter);

		// sub-table
		const columnWithSubTable = columns.find(
			(column) => isColumnWithSubTable(column) && column.subTable?.property === expandedSubTable
		) as CsvColumnWithSubTableConfig<TDto, unknown>;

		if (columnWithSubTable?.subTable !== undefined) {
			const subTable = await buildSubTableCsv(
				item,
				columnWithSubTable.subTable,
				columns.length,
				options
			);
			return `${row}${newline}${subTable}`;
		}

		return row;
	});

	// join header and all rows
	const dataRowsCsv = await Promise.all(dataRows);
	return [...headerRow, ...dataRowsCsv].join(newline);
}

function isColumnWithSubTable<TDto, STDto>(
	column: CsvColumnConfig<TDto>
): column is CsvColumnWithSubTableConfig<TDto, STDto> {
	return "subTable" in column;
}

// escapes CSV content by wrapping it in double quotes and escaping double quotes within the content
export function escapeCsvContent(content: string | number): string | number {
	return typeof content === "string" ? `"${content.replace(/"/g, '""')}"` : content;
}

async function buildSubTableCsv<TDto, STDto>(
	item: TDto,
	subTableConfig: SubCsvTableConfig<TDto, STDto>,
	parentTableColumnsCount: number,
	options: CsvFormatOptions
): Promise<string> {
	const { delimiter = ",", newline = "\n" } = options;
	const { columns, dataProvider } = subTableConfig;
	const leadingColumns = `${delimiter}`.repeat(parentTableColumnsCount);
	const data = await dataProvider(item);
	const subTable = await parseToCsv(data, columns, undefined, {
		...options,
		newline: `${newline}${leadingColumns}`,
	});
	return `${leadingColumns}${subTable}`;
}

function buildFileBlob(csvContent: string, contentPre: string, contentPos: string) {
	const universalBOM = new Uint8Array([0xef, 0xbb, 0xbf]); // UTF-8 BOM
	const blob = new Blob([universalBOM, contentPre, csvContent, contentPos], {
		type: "text/plain;charset=utf-8",
	});
	return blob;
}

function downloadFile(blob: Blob, fileName: string) {
	const url = URL.createObjectURL(blob);
	const link = document.createElement("a");
	link.setAttribute("href", url);
	link.setAttribute("download", fileName);
	link.click();
}

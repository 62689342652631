import React, { useState, createRef } from "react";
import { DataObserveKey, VisualComponent, Button, useTranslations } from "@siteimprove/fancylib";
import { useLabTranslations } from "../../../translations/translations";
import { FormElementWrapper } from "../../forms-and-inputs/form-element-wrapper/form-element-wrapper";
import { Form } from "../../forms-and-inputs/form/form";
import { Modal } from "../../overlay/modal/modal";
import { Radios } from "../../forms-and-inputs/radios/radios";
import { ActionBar } from "../../actions-and-controls/action-bar/action-bar";

type PagesOption = "all" | "visible";

type SubTableKey<T> = Extract<keyof T, string> | "none";

type OnExportHandler<TDto> = (options: {
	pageSize: number | undefined;
	subtablesOption: Extract<keyof TDto, string> | null;
	pagesOption: PagesOption;
}) => Promise<void> | void;

export type TableExporterProps<TDto> = {
	onExport: OnExportHandler<TDto>;
	buttonContent: React.ReactNode;
	modalTitle?: React.ReactNode;
	pageSize?: number;
	total?: number;
	expandableProperties?: { propertyName: Extract<keyof TDto, string>; displayName: string }[];
} & DataObserveKey &
	VisualComponent;

export function TableExporter<TDto>(props: TableExporterProps<TDto>): JSX.Element {
	const { onExport, buttonContent, modalTitle, pageSize, total, expandableProperties, ...rest } =
		props;

	const [showModal, setShowModal] = useState(false);
	const buttonRef = createRef<HTMLButtonElement>();

	return (
		<>
			<Button
				data-component="table-export"
				onClick={() => setShowModal(!showModal)}
				ref={buttonRef}
				{...rest}
			>
				{buttonContent}
			</Button>
			<ExportOptionsModal<TDto>
				headerTitle={modalTitle}
				onExport={onExport}
				shown={showModal}
				pageSize={pageSize}
				total={total}
				onClose={() => {
					setShowModal(false);
					buttonRef.current && buttonRef.current.focus();
				}}
				expandableProperties={expandableProperties}
			/>
		</>
	);
}

export type TableExportOptions<TDto> = Omit<
	TableExporterProps<TDto>,
	"buttonContent" | keyof VisualComponent
>;

export function useTableExport<TDto>(options?: TableExportOptions<TDto>): {
	enabled: boolean;
	modal: JSX.Element | undefined;
	trigger: () => void;
} {
	const [showModal, setShowModal] = useState(false);

	let modal;

	function handleOnExportClick() {
		if (options) {
			const { onExport, pageSize, total } = options;

			if (pageSize) {
				setShowModal(true);
			} else if (total !== undefined) {
				onExport({ pageSize: total, subtablesOption: null, pagesOption: "visible" });
			}
		}
	}

	if (options) {
		const { onExport, pageSize, total, modalTitle, expandableProperties } = options;
		const buttonRef = createRef<HTMLButtonElement>();

		if (pageSize && total !== undefined) {
			modal = (
				<ExportOptionsModal<TDto>
					onExport={onExport}
					headerTitle={modalTitle}
					shown={showModal}
					pageSize={pageSize}
					total={total}
					onClose={() => {
						setShowModal(false);
						buttonRef.current && buttonRef.current.focus();
					}}
					expandableProperties={expandableProperties}
				/>
			);
		}
	}

	return { enabled: options !== undefined, modal, trigger: handleOnExportClick };
}

type ExportOptionsModalProps<TDto> = {
	onExport: OnExportHandler<TDto>;
	shown: boolean;
	headerTitle?: React.ReactNode;
	pageSize?: number;
	total?: number;
	onClose: () => void;
	expandableProperties?: { propertyName: Extract<keyof TDto, string>; displayName: string }[];
};

function ExportOptionsModal<TDto>(props: ExportOptionsModalProps<TDto>): JSX.Element {
	const {
		expandableProperties,
		headerTitle,
		onClose,
		onExport,
		pageSize,
		shown,
		total: totalItems,
	} = props;

	const [includedSubtable, setIncludedSubtable] = useState<SubTableKey<TDto>>("none");
	const [pagesOption, setPagesOption] = useState<PagesOption>("all");
	const [inProgress, setInProgess] = useState<string | null>(null);
	const i18nLab = useLabTranslations();
	const i18nLib = useTranslations();

	const subtableOptions =
		expandableProperties &&
		expandableProperties.map((property) => {
			return {
				label: i18nLab.includeSubtablesOption.replace("[PROPERTY]", property.displayName),
				value: property.propertyName,
			};
		});

	const hasPagingOptions = !!pageSize && !!totalItems;

	const handleSubmit = async (action: string) => {
		setInProgess(action);
		await onExport({
			pageSize,
			subtablesOption: includedSubtable === "none" ? null : includedSubtable,
			pagesOption,
		});
		setInProgess(null);
		onClose();
	};

	return (
		<Modal shown={shown} headerTitle={headerTitle} onClose={onClose}>
			<Form>
				<Modal.Content>
					{subtableOptions && (
						<FormElementWrapper label={i18nLab.includeSubtables} name="include-subtables">
							<Radios<SubTableKey<TDto>>
								value={includedSubtable}
								onChange={(value) => {
									if (value !== "none") {
										setPagesOption("visible");
									}
									setIncludedSubtable(value);
								}}
							>
								{[
									{
										label: i18nLab.dontIncludeSubtables,
										value: "none",
									},
									...subtableOptions,
								].map((option) => (
									<Radios.Radio value={option.value} key={option.label}>
										{option.label}
									</Radios.Radio>
								))}
							</Radios>
						</FormElementWrapper>
					)}
					{hasPagingOptions && (
						<FormElementWrapper label={i18nLab.pages} name="pages">
							<Radios value={pagesOption} onChange={(v) => setPagesOption(v)}>
								{[
									{
										label: i18nLab.allPagesTagged
											.replace("[NUMBER_OF_PAGES]", Math.ceil(totalItems / pageSize).toString())
											.replace("[NUMBER_OF_ROWS]", totalItems.toString()),
										value: "all",
										disabled: includedSubtable !== "none",
									},
									{
										label: i18nLab.visiblePage.replace(
											"[NUMBER_OF_ROWS]",
											pageSize > totalItems ? totalItems.toString() : pageSize.toString()
										),
										value: "visible",
									},
								].map((option) => (
									<Radios.Radio value={option.value} key={option.label} disabled={option.disabled}>
										{option.label}
									</Radios.Radio>
								))}
							</Radios>
						</FormElementWrapper>
					)}
				</Modal.Content>
				<Modal.Footer>
					<ActionBar
						inProgressAction={inProgress}
						primary={{ children: i18nLab.exportLabel, onClick: (action) => handleSubmit(action) }}
						cancel={{ children: i18nLib.cancel, onClick: onClose }}
					/>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}

import React from "react";
import { Button, Icon, IconDownload } from "@siteimprove/fancylib";
import { useLabTranslations } from "../../../translations/translations";
import { CsvExporterOptions, useCsvExporter } from "../../../utils/csv-exporter";
import { ColumnConfig, ColumnsArray } from "../base-table/base-table";
import { TableExportOptions, useTableExport } from "./table-exporter";

type DataProvider<TDto> = ({
	pageNumber,
	pageSize,
}: {
	pageNumber: number;
	pageSize: number;
}) => Promise<TDto[]>;

export type TableCsvExporterOptions<TDto> = Omit<CsvExporterOptions<TDto>, "expandedSubTable"> & {
	columns: ColumnsArray<TDto>;
	dataProvider: DataProvider<TDto>;
	pageNumber: number;
	pageSize: number;
	total: number;
	maxPageSize?: number;
	expandableProperties?: { propertyName: Extract<keyof TDto, string>; displayName: string }[];
};

// hook API
export function useTableCsvExporter<TDto>(
	options: TableCsvExporterOptions<TDto> | undefined
): ReturnType<typeof useTableExport> {
	const columnsWithCsvConfig = useCsvExportableColumns(options?.columns || []);
	const exportToCsv = useCsvExporter<TDto>(columnsWithCsvConfig.map((c) => c.csv!).flat());
	const i18n = useLabTranslations();

	let tableExporterOptions: TableExportOptions<TDto> | undefined;
	if (options !== undefined) {
		const {
			dataProvider,
			pageNumber,
			pageSize,
			maxPageSize = 1000,
			total,
			expandableProperties,
			columns,
			...rest
		} = options;

		tableExporterOptions = {
			onExport: async (opt) => {
				const { pagesOption, subtablesOption } = opt;
				const data =
					pagesOption === "all"
						? await fetchAllPages(dataProvider, maxPageSize, total)
						: await dataProvider({ pageNumber, pageSize });
				return exportToCsv(data, { ...rest, expandedSubTable: subtablesOption ?? undefined });
			},
			modalTitle: i18n.exportToCsv,
			pageSize: pageSize,
			total,
			expandableProperties,
		};
	}

	return useTableExport(tableExporterOptions);
}

// component API
export function TableCsvExporter<TDto>(props: TableCsvExporterOptions<TDto>): JSX.Element {
	const { trigger, modal } = useTableCsvExporter(props);
	const i18n = useLabTranslations();
	return (
		<>
			<Button onClick={trigger}>
				<Icon>
					<IconDownload />
				</Icon>
				{i18n.exportToCsv}
			</Button>
			{modal}
		</>
	);
}

// filter csv exportable columns from generic columns array
function useCsvExportableColumns<TDto>(columns: ColumnsArray<TDto>): ColumnConfig<TDto>[] {
	const csvExportableColumns: ColumnConfig<TDto>[] = [];
	columns.forEach((column) => {
		// skip columns that are not exportable
		if (!column || column.csv === null) return;

		// one-to-many columns case
		if (Array.isArray(column.csv)) {
			csvExportableColumns.push(column);
			return;
		}

		// try to infer csv config from column config
		const headerContent = "content" in column.header && column.header.content;
		if (!headerContent) return;

		const csvColumnConfig: ColumnConfig<TDto> = {
			...column,
			csv: {
				header: headerContent || "",
				render: (dto, cellPosition) => {
					const renderedCell = column.render(dto, cellPosition);
					if (typeof renderedCell === "string" || typeof renderedCell === "number")
						return renderedCell;
					return null;
				},
				...column.csv,
			},
		};
		csvExportableColumns.push(csvColumnConfig);
	});
	return csvExportableColumns;
}

async function fetchAllPages<TDto>(
	dataProvider: DataProvider<TDto>,
	maxPageSize: number,
	total: number
): Promise<TDto[]> {
	const pages = Math.ceil(total / maxPageSize);
	const items = [];
	for (let i = 0; i < pages; i++) {
		items.push(
			await dataProvider({
				pageNumber: i + 1,
				pageSize: maxPageSize,
			})
		);
	}
	return items.flat();
}
